interface TitleProps {
  title: string;
  desc?: string;
}

export default function Title({ title, desc }: TitleProps) {
  return (
    <div className="mb-2 flex flex-col gap-2 lg:mb-6 lg:flex-row">
      <h1 className="bg-white text-xl font-semibold lg:text-2xl">{title}</h1>
      {desc && <h2 className="pb-2.5 text-sm">{desc}</h2>}
    </div>
  );
}
