import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { omit } from "lodash-es";
import { toast } from "react-toastify";

import { QUERY_KEYS } from "constants/queryKeys";
import {
  CollectionBase,
  CollectionItem,
  ContentItem,
  DeleteResponses,
  EngineBase,
  GetListParams,
  GetListResponses,
  GetSingleParams,
  PatchResponses,
  PostResponses,
  SaveItem,
  UidSingleParams,
} from "types/schema";
import { EngineError } from "types/types";

import instance from "@server/axiosInstance";
import { queryClient } from "@server/queryClient";

// [POST] /collection/
export type PostCollectionParams = {
  body: Partial<Omit<CollectionBase, keyof EngineBase>> & {
    title: CollectionBase["title"];
    kind: CollectionBase["kind"];
  };
};
export const postCollection = async ({ body }: PostCollectionParams) => {
  const { data } = await instance({
    url: `/collection/`,
    method: "post",
    data: body,
  });
  return data;
};
export const usePostCollection = (
  options?: UseMutationOptions<
    PostResponses,
    AxiosError<EngineError>,
    PostCollectionParams
  >
) => {
  return useMutation<
    PostResponses,
    AxiosError<EngineError>,
    PostCollectionParams
  >((params) => postCollection(params), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.collection.list,
        refetchType: "all",
      });
      toast.success("컬렉션을 생성했어요.");
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      console.log(error.response?.data.detail);
      toast.error("컬렉션 생성을 실패했어요.");
      options?.onError && options.onError(error, variables, context);
    },
    ...omit(options, ["onSuccess", "onError"]),
  });
};

// [PATCH] /collection/{uid}
export type PatchCollectionByUidParams = {
  body: Partial<Omit<CollectionBase, keyof EngineBase>>;
} & UidSingleParams;
export const patchCollectionByUid = async ({
  uid,
  body,
}: PatchCollectionByUidParams) => {
  const { data } = await instance({
    url: `/collection/${uid}`,
    method: "patch",
    data: body,
  });
  return data;
};
export const usePatchCollectionByUid = (
  options?: UseMutationOptions<
    PatchResponses,
    AxiosError<EngineError>,
    PatchCollectionByUidParams
  >
) => {
  return useMutation<
    PatchResponses,
    AxiosError<EngineError>,
    PatchCollectionByUidParams
  >((params) => patchCollectionByUid(params), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.collection.list,
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.collection.single(variables.uid),
        refetchType: "all",
      });
      toast.success("컬렉션을 업데이트 했어요.");
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      console.log(error.response?.data.detail);
      toast.error("컬렉션 업데이트를 실패했어요.");
      options?.onError && options.onError(error, variables, context);
    },
    ...omit(options, ["onSuccess", "onError"]),
  });
};

// [DELETE] /collection/{uid}
export const deleteCollectionByUid = async ({ uid }: UidSingleParams) => {
  const { data } = await instance({
    url: `/collection/${uid}`,
    method: "delete",
  });
  return data;
};
export const useDeleteCollectionByUid = (
  options?: UseMutationOptions<
    DeleteResponses,
    AxiosError<EngineError>,
    UidSingleParams
  >
) => {
  return useMutation<DeleteResponses, AxiosError<EngineError>, UidSingleParams>(
    (params) => deleteCollectionByUid(params),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.collection.list,
          refetchType: "all",
        });
        toast.success("컬렉션을 삭제했어요.");
        options?.onSuccess && options.onSuccess(data, variables, context);
      },
      onError: (error, variables, context) => {
        const status = error.response?.status;
        if (status === 401) {
          return toast.error("컬렉션을 삭제할 권한이 없어요.");
        }
        if (status === 404) {
          return toast.error("이미 삭제된 컬렉션예요.");
        }
        toast.error("컬렉션 삭제를 실패했어요.");
        options?.onError && options.onError(error, variables, context);
      },
      ...omit(options, ["onSuccess", "onError"]),
    }
  );
};

// [GET] /collection/{uid}
export const getCollectionByUid = async ({
  uid,
  projection,
}: GetSingleParams): Promise<CollectionItem> => {
  const { data } = await instance({
    url: `/collection/${uid}`,
    method: "get",
    params: {
      projection,
    },
  });
  return data;
};
export const useGetCollectionByUid = (
  params: GetSingleParams,
  options?: UseQueryOptions<CollectionItem, AxiosError<EngineError>>
) => {
  return useQuery<CollectionItem, AxiosError<EngineError>>(
    [...QUERY_KEYS.collection.single(params.uid), omit(params, params.uid)],
    () => getCollectionByUid(params),
    {
      ...options,
    }
  );
};

// [GET] /collection/
export type GetCollectionResponses = GetListResponses<CollectionItem>;
export const getCollection = async ({
  keyword,
  _size,
  _from,
  sort,
  direction,
  _source,
  query,
}: GetListParams): Promise<GetCollectionResponses> => {
  const { data } = await instance({
    url: `/collection/`,
    method: "get",
    params: {
      keyword,
      _size,
      _from,
      sort,
      direction,
      _source,
    },
    data: query,
  });
  return data;
};
export const useGetCollection = <T = GetCollectionResponses>(
  params: GetListParams,
  options?: UseQueryOptions<GetCollectionResponses, AxiosError<EngineError>, T>
) => {
  return useQuery<GetCollectionResponses, AxiosError<EngineError>, T>(
    [...QUERY_KEYS.collection.list, params],
    () => getCollection(params),
    {
      ...options,
    }
  );
};

// [GET] /collection/list/{uid}
export type GetCollectionListByUidParams = GetListParams & UidSingleParams;
export type GetCollectionListByUidResponses = GetListResponses<
  SaveItem & { content: ContentItem }
>;
export const getCollectionListByUid = async ({
  uid,
  keyword,
  _size,
  _from,
  sort,
  direction,
  _source,
  query,
}: GetCollectionListByUidParams): Promise<GetCollectionListByUidResponses> => {
  const { data } = await instance({
    url: `/collection/list/${uid}`,
    method: "get",
    params: {
      keyword,
      _size,
      _from,
      sort,
      direction,
      _source,
    },
    data: query,
  });
  return data;
};
export const useGetCollectionListByUid = (
  params: GetCollectionListByUidParams,
  options?: UseQueryOptions<
    GetCollectionListByUidResponses,
    AxiosError<EngineError>
  >
) => {
  return useQuery<GetCollectionListByUidResponses, AxiosError<EngineError>>(
    [...QUERY_KEYS.collection.singleList(params.uid), omit(params, params.uid)],
    () => getCollectionListByUid(params),
    {
      ...options,
    }
  );
};
