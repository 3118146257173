export interface Category {
  label: string;
  value: string;
  number: number;
}

export const CATEGORIES: Category[] = [
  { label: "전체", value: "all", number: 0 },
  { label: "코드", value: "code", number: 6 },
  { label: "저장소", value: "repo", number: 3 },
  { label: "모델", value: "model", number: 7 },
  { label: "데이터셋", value: "dataset", number: 8 },
  { label: "문서", value: "document", number: 10 },
  { label: "뉴스", value: "news", number: 1 },
  { label: "소셜미디어", value: "social", number: 2 },
  { label: "블로그", value: "blog", number: 9 },
  { label: "웹사이트", value: "website", number: 4 },
  { label: "동영상", value: "video", number: 5 },
  { label: "기타", value: "etc", number: 15 },
];

export const FIELDS = [
  "대학생",
  "대학원생",
  "초등/중등/고등학생",
  "교수",
  "스타트업",
  "일반기업",
  "기타",
];

export const INTEREST = [
  "언어",
  "뷰티",
  "사람",
  "건강",
  "동물",
  "자동차",
  "식품",
  "예술",
  "창업",
  "통신",
  "환경",
  "헬스케어",
  "금융 및 경제",
  "물류",
  "기타",
];

export const CUSTOMERS = [
  {
    img: "sungkyunkwan.png",
    mainTitle: "성균관대학교",
    subTitle: "미디어커뮤니케이션학과",
  },
  { img: "unist.png", mainTitle: "울산과학기술원", subTitle: "수리과학과" },
  { img: "yeungnam.png", mainTitle: "영남대학교", subTitle: "통계학과" },
];

export const COURSE_VIEW_OPTIONS = [
  {
    label: "15개씩 보기",
    value: "15",
  },
  {
    label: "30개씩 보기",
    value: "30",
  },
  {
    label: "45개씩 보기",
    value: "45",
  },
  {
    label: "60개씩 보기",
    value: "60",
  },
];
