import { useRef } from "react";

import dynamic from "next/dynamic";
import Image from "next/image";
import { useRouter } from "next/router";

import { Carousel } from "@mantine/carousel";
import { Skeleton, Spoiler } from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";

import { HomeDiv } from "components/common/HomeDiv";
import Button from "components/common/button/Button";
import { HEIGHT } from "constants/constants";
import { CUSTOMERS } from "constants/data";
import Wrapper from "containers/common/Wrapper";
import { FEATURE_DATA, FeatureItem } from "pages/coredot/howto";

import useFadeIn from "@client/useFadeIn";
import { useGetCollection } from "@server/engineCollection";
import { useGetContentExternal } from "@server/engineContent";

const ContentCard = dynamic(() => import("containers/externals/ExternalCard"), {
  ssr: false,
  loading: () => <Skeleton height={HEIGHT.content} />,
});
const CollectionCard = dynamic(
  () => import("containers/collections/CollectionCard"),
  {
    ssr: false,
    loading: () => <Skeleton height={HEIGHT.collection} />,
  }
);

export default function Home() {
  const router = useRouter();

  const autoplay = useRef(Autoplay({ delay: 3000 }));
  const fadeIn1 = useFadeIn(1.5, 0);
  const fadeIn2 = useFadeIn(1.5, 1);
  const fadeIn3 = useFadeIn(1.5, 2);
  const fadeIn4 = useFadeIn(1.5, 3);

  const size = 3;
  const { data: contentsData, isLoading: contentsLoading } =
    useGetContentExternal({ _size: size }, { suspense: false });
  const { data: collectionsData, isLoading: collectionsLoading } =
    useGetCollection({ _size: size }, { suspense: false });

  return (
    <div className="space-y-10 text-center">
      <section className="mx-auto my-10 max-w-6xl px-4 text-center lg:my-16 lg:px-6">
        <h1
          className="mb-4 text-5xl font-extrabold sm:text-6xl md:text-7xl lg:text-8xl"
          data-aos="zoom-y-out"
        >
          <span {...fadeIn2}>All About AI</span>
          <br />
          <span
            className="leading-tighter bg-gradient-to-r from-blue-500 to-teal-400 bg-clip-text tracking-tight text-transparent"
            {...fadeIn3}
          >
            코어닷투데이
          </span>
        </h1>
        <div
          className="mx-auto mt-10 max-w-3xl text-xl text-slate-500 lg:text-2xl"
          {...fadeIn1}
        >
          다가오는 AI 시대, <br className="lg:hidden" />
          <strong>코어닷투데이</strong>에서 준비하세요!
        </div>
        <div
          className="mx-auto mt-10 max-w-3xl text-xl text-slate-500 lg:text-2xl"
          {...fadeIn4}
        >
          쉽고 빠르게 AI를 직접 경험하고 <br className="lg:hidden" />
          먼저 활용하여 앞서 나가세요!
        </div>
      </section>

      {/* <div className="grid h-[200px] grid-cols-7 border border-gray-200 text-center">
        <div className="col-span-2 flex flex-col items-center justify-center border border-gray-200">
          <span className="text-2xl font-bold">1,000+</span>
          <span className="text-lg font-semibold">인공지능 카테고리</span>
        </div>
        <div className="col-span-3 flex flex-col items-center justify-center border border-gray-200">
          <span className="text-2xl font-bold">50,000+</span>
          <span className="text-lg font-semibold">인공지능 관련 콘텐츠</span>
        </div>
        <div className="col-span-2 flex flex-col items-center justify-center border border-gray-200">
          <span className="text-2xl font-bold">200+</span>
          <span className="text-lg font-semibold">실행 가능한 기술</span>
        </div>
      </div> */}

      <HomeDiv
        title={
          <span className="text-2xl lg:text-3xl">
            최신 AI 기술 트렌드를 한눈에!
          </span>
        }
      >
        <Button
          eventName="콘텐츠 보러가기"
          onClick={() => router.push("/links")}
          className="m-auto"
          variant="subtle"
        >
          콘텐츠 보러가기
        </Button>
        <div
          className={`mx-auto grid max-w-4xl grid-cols-1 gap-3 text-left sm:grid-cols-2 md:grid-cols-3`}
        >
          {contentsLoading && <Skeleton height={HEIGHT.content} />}
          {contentsData &&
            contentsData?.hits.map((item) => (
              <Wrapper key={item.uid}>
                <ContentCard data={item} />
              </Wrapper>
            ))}
        </div>
      </HomeDiv>

      <HomeDiv
        title={
          <>
            콘텐츠를 모아서 <br className="lg:hidden" />
            컬렉션으로!
          </>
        }
      >
        <Button
          eventName="컬렉션 보러가기"
          onClick={() => router.push("/collections")}
          className="m-auto"
          variant="subtle"
        >
          컬렉션 보러가기
        </Button>
        <div
          className={`mx-auto grid max-w-4xl grid-cols-1 gap-3 text-left sm:grid-cols-2 md:grid-cols-3`}
        >
          {collectionsLoading && <Skeleton height={HEIGHT.collection} />}
          {collectionsData &&
            collectionsData?.hits.map((item) => (
              <Wrapper key={item.uid}>
                <CollectionCard data={item} />
              </Wrapper>
            ))}
        </div>
      </HomeDiv>

      <HomeDiv title="코어닷투데이 서비스는?">
        <div>
          최신 AI 정보를 확인하고, 그 정보들을 모아서 컬렉션으로 등록 가능해요.
          <br />
          또한, 코어닷투데이 내부에서 코드를 실행해볼 수도 있어요.
        </div>
      </HomeDiv>

      <Carousel
        className="mx-auto max-w-4xl"
        mx="auto"
        withIndicators
        loop
        height={350}
        styles={{
          indicator: {
            width: 12,
            height: 4,
            transition: "width 250ms ease",

            "&[data-active]": {
              width: 40,
            },
          },
        }}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
      >
        {FEATURE_DATA.map((item) => (
          <Carousel.Slide key={item.title}>
            <FeatureCard {...item} />
          </Carousel.Slide>
        ))}
      </Carousel>

      <HomeDiv
        title={
          <>
            코어닷투데이와 <br className="lg:hidden" />
            함께하는 고객
          </>
        }
      >
        <div className="mx-auto grid max-w-3xl grid-cols-3">
          {CUSTOMERS.map((item) => (
            <div
              key={item.mainTitle}
              className="flex flex-col justify-center text-center"
            >
              <Image
                className="mx-auto"
                src={`/images/customers/${item.img}`}
                alt={item.mainTitle + item.subTitle}
                height={150}
                width={150}
              />
              <span className="font-semibold">{item.mainTitle}</span>
              <span>{item.subTitle}</span>
            </div>
          ))}
        </div>
      </HomeDiv>

      <div className="flex flex-col space-y-5 bg-gray-200 p-10">
        <span className="text-2xl font-bold">코어닷투데이의 강력한 콘텐츠</span>
        <span className="text-xl font-semibold">
          그럼 지금 바로 경험해 볼까요?
        </span>
        <Button
          eventName="바로 시작하기"
          style={{ width: "50%", margin: "0 auto" }}
          size="lg"
          onClick={() => {
            router.push("/auth/signup");
          }}
        >
          바로 시작하기
        </Button>
      </div>
    </div>
  );
}

export const FeatureCard = ({ title, imgSrc, description }: FeatureItem) => {
  return (
    <div className="flex flex-col items-center">
      <div className="lg:flew-row flex w-full max-w-3xl flex-col items-center justify-center rounded-lg bg-white p-4 shadow-lg">
        <Image
          src={imgSrc}
          alt="feature card image"
          width={400}
          height={400}
          className="!relative object-contain lg:h-[290px]"
        />
        <div>
          <h3 className="mb-4 text-3xl font-bold">{title}</h3>
          <Spoiler
            showLabel={<div className="">살펴보기</div>}
            hideLabel="숨기기"
            maxHeight={0}
          >
            <div className="mb-4 text-sm text-slate-700">{description}</div>
          </Spoiler>
        </div>
      </div>
    </div>
  );
};
