import { useEffect, useRef } from "react";

export default function useFadeIn(duration: number = 1, delay: number = 0) {
  const element = useRef<any>();
  useEffect(() => {
    if (element.current) {
      const { current } = element;
      current.style.transition = `opacity ${duration}s ease-in-out ${delay}s`;
      current.style.opacity = "1";
    }
  }, [duration, delay]);

  if (typeof duration !== "number" || typeof delay !== "number") {
    return;
  }
  return { ref: element, style: { opacity: 0 } };
}
