import { Loader, LoaderProps } from "@mantine/core";

export interface LoadingPageProps {
  size?: number | "xs" | "sm" | "md" | "lg" | "xl";
  isCenter?: boolean;
  text?: React.ReactNode;
  loaderProps?: LoaderProps;
  className?: string;
  divProps?: Omit<React.HTMLAttributes<HTMLDivElement>, "className">;
}

export default function LoadingPage({
  size = "xl",
  isCenter = false,
  className = "my-32",
  text = (
    <>
      정보를 불러오는 중이예요 <br />
      조금만 기다려주세요
    </>
  ),
  loaderProps,
  divProps,
}: LoadingPageProps) {
  return isCenter ? (
    <div
      className={`m-auto flex flex-col items-center justify-center gap-2 text-center text-sm ${className}`}
      {...divProps}
    >
      <Loader size={size} {...loaderProps} />
      <span>{text}</span>
    </div>
  ) : (
    <div className={`flex items-center gap-2 text-sm`}>
      <Loader size={size} {...loaderProps} />
      <span>{text}</span>
    </div>
  );
}
