import { Suspense } from "react";

import { ErrorBoundary } from "containers/common/ErrorBoundary";
import ErrorPage, { ErrorPageProps } from "containers/common/ErrorPage";
import LoadingPage, { LoadingPageProps } from "containers/common/LoadingPage";

interface WrapperProps {
  children: React.ReactNode;
  loadingProps?: LoadingPageProps;
  errorProps?: ErrorPageProps;
}

export default function Wrapper({
  children,
  loadingProps,
  errorProps,
}: WrapperProps) {
  return (
    <Suspense fallback={<LoadingPage {...loadingProps} />}>
      <ErrorBoundary fallback={<ErrorPage {...errorProps} />}>
        {children}
      </ErrorBoundary>
    </Suspense>
  );
}
