import { useRouter } from "next/router";

import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { omit } from "lodash-es";
import { toast } from "react-toastify";

import { QUERY_KEYS } from "constants/queryKeys";
import {
  ContentItem,
  DeleteResponses,
  GetListParams,
  GetListResponses,
  GetSingleParams,
  LinkItem,
  PostResponses,
  UidSingleParams,
} from "types/schema";
import { EngineError } from "types/types";

import instance from "@server/axiosInstance";
import { queryClient } from "@server/queryClient";

// [POST] /content/external/
export type PostContentExternalParams = {
  link: string;
};
export type PostContentExternalResponses = Omit<PostResponses, "uid"> & {
  content_uid: string;
  link_uid: string;
  detail: string;
};
export const postContentExternal = async ({
  link,
}: PostContentExternalParams) => {
  const { data } = await instance({
    url: `/content/external/`,
    method: "post",
    params: {
      link,
    },
  });
  return data;
};
export const usePostContentExternal = (
  options?: UseMutationOptions<
    PostContentExternalResponses,
    AxiosError<EngineError>,
    PostContentExternalParams
  >
) => {
  return useMutation<
    PostContentExternalResponses,
    AxiosError<EngineError>,
    PostContentExternalParams
  >((params) => postContentExternal(params), {
    onSuccess: (data, variables, context) => {
      if (
        data.status === 201 &&
        data.detail ===
          "이미 누군가 수집한 link입니다. 당신의 저장 행동만 기록했습니다."
      ) {
        return toast.success(
          "다른 사람이 먼저 코어닷투데이에 등록한 콘텐츠예요. 내 콘텐츠 메뉴에서 등록하신 콘텐츠를 확인해보세요."
        );
      }
      toast.success("외부콘텐츠를 등록했어요.");
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.external.list,
        refetchType: "all",
      });
      options?.onSuccess && options.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      const status = error.response?.status;
      const detail = error.response?.data.detail;
      if (
        status === 422 &&
        detail === "내가 이미 수집하고 저장한 행동이 있습니다."
      ) {
        return toast.error("이미 등록하신 콘텐츠예요.");
      }
      toast.error("외부콘텐츠 등록을 실패했어요.");
      options?.onError && options.onError(error, variables, context);
    },
    ...omit(options, ["onSuccess", "onError"]),
  });
};

// [DELETE] /content/external/
export const deleteContentExternal = async ({ uid }: UidSingleParams) => {
  const { data } = await instance({
    url: `/content/external/`,
    method: "delete",
    params: {
      uid,
    },
  });
  return data;
};
export const useDeleteContentExternal = (
  options?: UseMutationOptions<
    DeleteResponses,
    AxiosError<EngineError>,
    UidSingleParams
  >
) => {
  return useMutation<DeleteResponses, AxiosError<EngineError>, UidSingleParams>(
    (params) => deleteContentExternal(params),
    {
      onSuccess: (data, variables, context) => {
        toast.success("외부콘텐츠를 삭제했어요.");
        queryClient.invalidateQueries({
          queryKey: QUERY_KEYS.external.list,
          refetchType: "all",
        });
        options?.onSuccess && options.onSuccess(data, variables, context);
      },
      onError: (error, variables, context) => {
        const status = error.response?.status;
        const detail = error.response?.data.detail;
        if (status === 404) {
          return toast.error("이미 삭제되었어요. 새로고침을 해주세요.");
        }
        toast.error("외부콘텐츠 삭제를 실패했어요.");
        options?.onError && options.onError(error, variables, context);
      },
      ...omit(options, ["onSuccess", "onError"]),
    }
  );
};

// [GET] /content/external/{uid}/
export const getContentExternalByUid = async ({
  uid,
  projection,
}: GetSingleParams): Promise<ContentItem> => {
  const { data } = await instance({
    url: `/content/external/${uid}/`,
    method: "get",
    params: {
      projection,
    },
  });
  return data;
};
export const useGetContentExternalByUid = (
  params: GetSingleParams,
  options?: UseQueryOptions<ContentItem, AxiosError<EngineError>>
) => {
  const router = useRouter();
  return useQuery<ContentItem, AxiosError<EngineError>>(
    [...QUERY_KEYS.external.single(params.uid)],
    () => getContentExternalByUid(params),
    {
      enabled: !!params.uid,
      onError: (error) => {
        if (
          error.response?.data?.detail.includes(
            "Already deleted content for uid"
          )
        ) {
          toast("삭제된 콘텐츠 입니다.");
          router.back();
        }
        options?.onError && options.onError(error);
      },
      ...omit(options, ["onError"]),
    }
  );
};

// [GET] /content/external/
export type GetContentExternalResponses = GetListResponses<ContentItem>;
export const getContentExternal = async ({
  keyword,
  _size,
  _from,
  sort,
  direction,
  _source,
  query,
}: GetListParams): Promise<GetContentExternalResponses> => {
  const { data } = await instance({
    url: `/content/external/`,
    method: "get",
    params: {
      keyword,
      _size,
      _from,
      sort,
      direction,
      _source,
    },
    data: query,
  });
  return data;
};
export const useGetContentExternal = (
  params: GetListParams,
  options?: UseQueryOptions<
    GetContentExternalResponses,
    AxiosError<EngineError>
  >
) => {
  return useQuery<GetContentExternalResponses, AxiosError<EngineError>>(
    [...QUERY_KEYS.external.list],
    () => getContentExternal(params),
    {
      ...options,
    }
  );
};

// [GET] /content/external/my/
export type GetContentExternalMyResponses = GetListResponses<
  LinkItem<ContentItem>
>;
export const getContentExternalMy = async ({
  keyword,
  _size,
  _from,
  sort,
  direction,
  _source,
  query,
}: GetListParams): Promise<GetContentExternalMyResponses> => {
  const { data } = await instance({
    url: `/content/external/my/`,
    method: "get",
    params: {
      keyword,
      _size,
      _from,
      sort,
      direction,
      _source,
    },
    data: query,
  });
  return data;
};
export const useGetContentExternalMy = (
  params: GetListParams,
  options?: UseQueryOptions<
    GetContentExternalMyResponses,
    AxiosError<EngineError>
  >
) => {
  return useQuery<GetContentExternalMyResponses, AxiosError<EngineError>>(
    [...QUERY_KEYS.external.my, params],
    () => getContentExternalMy(params),
    {
      ...options,
    }
  );
};
