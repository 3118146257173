interface HomeDivProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

export const HomeDiv = ({ title, children, className }: HomeDivProps) => {
  return (
    <div className={`space-y-5 ${className}`}>
      <h2 className="text-3xl font-bold">{title}</h2>
      {children}
    </div>
  );
};
