import Image from "next/image";

import { Spoiler } from "@mantine/core";

import Title from "components/common/Title";
import SEO from "containers/common/SEO";

import useFadeIn from "@client/useFadeIn";

const size = 500;

export const IMAGES = {
  service: "/images/marketing/service.png",
  discover: "/images/marketing/discover.png",
  save: "/images/marketing/save.png",
  run: "/images/marketing/run.png",
  discover2: "/images/character/2x/discover-withtext.png",
  save2: "/images/character/2x/save.png",
  run2: "/images/character/2x/run.png",
};

export interface FeatureItem {
  title: string;
  imgSrc: string;
  description: React.ReactNode;
  idx?: number;
}

export const FEATURE_DATA: FeatureItem[] = [
  {
    title: "나만의 AI 라이브러리",
    imgSrc: IMAGES.service,
    description: (
      <>
        <p>
          코어닷투데이는 AI 지식을 습득할 수 있는 플랫폼으로, 사용자들은 나만의
          AI 라이브러리를 구축할 수 있습니다.
        </p>
      </>
    ),
  },
  {
    title: "AI 정보를 발견해보세요",
    imgSrc: IMAGES.discover,
    description: (
      <>
        <p>
          코어닷투데이는 인공지능 분야에서 유용한 정보들을 제공합니다. 최신
          트렌드, 개발 방법, 적용 사례 등을 다루며, 이를 통해 당신도 AI 지식을
          습득할 수 있습니다.
        </p>
        <p>
          또한, 코어닷투데이는 AI에 관련된 다양한 학습 자료들을 제공합니다.
          사용자들은 책, 강의, 코스 등을 통해 AI에 대한 지식을 깊이 있게 습득할
          수 있습니다.
        </p>
        <p>Tip! 콘텐츠, 컬렉션 메뉴를 통해 원하는 정보들을 발견해 보세요!</p>
        <p>
          코어닷의 큐레이터들은 다양한 정보들을 계속해서 수집하고 있습니다.
          이러한 정보들은 여러 AI들이 요약도 하고 태그도 달고 다양한 메타
          정보들을 생성합니다. 이러한 정보들 중에 가치 있는 정보들을 발견하게
          되죠!
        </p>
      </>
    ),
  },
  {
    title: "AI 정보를 저장해보세요",
    imgSrc: IMAGES.save,
    description: (
      <>
        <p>
          발견한 정보는 그냥 두면 잊어버리기 쉽상이죠. 그래서 맘에 드는 정보는
          스크랩 해두는 것이 필요합니다. 나만의 스크랩 폴더에 저장해 보세요. 내
          스크랩 폴더에 저장해 두고 언제든 찾아볼 수 있고, 또 컬렉션으로
          발행하여 다른 사람들과 함께 지식을 공유할 수 있습니다.
        </p>
        <p>Tip! `스크랩` 버튼을 눌러 스크랩 폴더로 저장해 보세요!</p>
        <p>
          발견된 정보 중에 가치 있는 정보들은 “스크랩” 버튼을 통해 저장할 수
          있습니다. 저장한 정보들은 차곡차곡 쌓여서 나만의 라이브러리를 만들 수
          있게 됩니다.
        </p>
      </>
    ),
  },
  {
    title: "AI 코드를 실행해보세요",
    imgSrc: IMAGES.run,
    description: (
      <>
        <p>
          더불어, 코어닷투데이는 AI를 실행할 수 있는 코드도 제공합니다. 이를
          활용하면 사용자들은 직접 AI를 실행하여 결과를 확인할 수 있습니다.
          코어닷투데이는 클라우드 자원을 활용하여 사용자들이 AI를 실행하는 데
          필요한 인프라를 제공합니다. 이를 통해 사용자들은 AI에 대한 이론적인
          이해뿐만 아니라, 실제로 AI를 실행해보며 더욱 깊이 있는 학습을 할 수
          있습니다.
        </p>
        <p>Tip! `실행하기` 버튼을 눌러 직접 AI 코드를 실행해 보세요!</p>
        <p>
          나만의 AI 라이브러리가 만들어 지고, 여기서 더 쉬운 이해를 돕기 위한
          작동 코드들을 직접 돌려볼 수 있어요. 직접 실행하고 고쳐보면서 복잡하고
          어려운 AI를 내 진짜 지식으로 만들 수 있습니다!
        </p>
      </>
    ),
  },
];
export default function Howto() {
  const title = "이용방법";

  return (
    <div>
      <SEO title={title} />
      <Title title={title} />

      <div className="items-cente my-10 flex flex-col gap-20">
        {FEATURE_DATA.map((item, idx) => (
          <FeatureCard key={item.title} {...item} idx={idx} />
        ))}
      </div>
    </div>
  );
}

export const FeatureCard = ({
  title,
  imgSrc,
  description,
  idx,
}: FeatureItem) => {
  const fadeIn = useFadeIn();
  return (
    <div
      className={`flex ${
        idx && idx % 2 === 1 ? "flex-row" : "flex-row-reverse"
      } w-full max-w-4xl items-center justify-center gap-4 rounded-lg bg-white p-4`}
      {...fadeIn}
    >
      <Image src={imgSrc} alt="feature card image" width={400} height={400} />
      <div className="flex flex-col items-center">
        <h3 className="mb-4 text-3xl font-bold">{title}</h3>
        <div className="mb-4 text-slate-700">{description}</div>
      </div>
    </div>
  );
};
