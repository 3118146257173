import Head from "next/head";

interface SEOProps {
  title?: string | undefined;
  summary?: string | undefined;
  image?: string | undefined;
  tags?: string[] | undefined;
  author?: string | undefined;
}

const altImg = "/images/ogImage.png";

export default function SEO({
  title = "",
  summary = "나만의 AI 라이브러리. 나만의 온라인 서재에서 AI 정보를 만나보세요. All About AI",
  image = altImg,
  tags = [],
  author,
}: SEOProps) {
  const titleText = title ? `${title} | 코어닷투데이` : "코어닷투데이";
  const keywords = `${tags?.join(
    ","
  )}코어닷투데이,Core.Today,AI,인공지능,Artificial Intelligence,딥러닝,Deep Learning,대학원생,대학생,IT,개발자,주피터노트북,ipynb,코랩,colab,ChatGPT,Model,Stable Diffusion,Transformer,GPT,Bert,데이터 사이언스,데이터,데이터 분석,데이터 놀이터,데이터 스토리,데이터 스토리텔링,데이터 크리에이터 플랫폼`;
  return (
    <Head>
      <title>{titleText}</title>

      {/* <!-- Twitter --> */}
      <meta name="twitter:site" content="@coredottoday" />
      <meta name="twitter:creator" content="@coredottoday" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={titleText} />
      <meta
        name="twitter:description"
        content={summary?.substring(0, 100).replaceAll("\n", " ")}
      />
      <meta name="twitter:image" content={image} />

      {/* <!-- Facebook --> */}
      <meta property="og:site_name" content="코어닷투데이 (Core.Today)" />
      <meta property="og:title" content={titleText} />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content={summary?.substring(0, 100).replaceAll("\n", " ")}
      />
      <meta property="og:image" content={image} />
      <meta property="og:image:secure_url" content={image} />

      {/* <!-- Meta Tags --> */}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {author && <meta name="author" content={author} />}
      <meta
        name="description"
        content={summary?.substring(0, 100).replaceAll("\n", " ")}
      />
      <meta name="keyword" content={keywords} />
    </Head>
  );
}
